import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginComponent from '../components/LoginComponent.vue'
import NoticeComponent from '../components/NoticeComponent.vue'
import ServiceManageComponent from '../components/ServiceManageComponent.vue'
import SupportComponent from '../components/SupportComponent.vue'
import BoardComponent from '../components/BoardComponent.vue'
import NoticeIndexComponent from '../components/NoticeIndexComponent.vue'
import PaymentManageComponent from '../components/PaymentManageComponent.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginComponent
  },
  {
    path: '/notice',
    name: 'notice',
    component: NoticeComponent,
    meta: {
      auth: true
    }
  },
  {
    path: '/support',
    name: 'support',
    component: SupportComponent,
    meta: {
      auth: true
    }
  },
  {
    path: '/board',
    name: 'board',
    component: BoardComponent,
    meta: {
      auth: true
    }
  },
  {
    path: '/service',
    name: 'service',
    component: ServiceManageComponent,
    meta: {
      auth: true
    }
  },
  {
    path: '/notice/:index',
    name: 'noticeindex',
    component: NoticeIndexComponent,
    meta: {
      auth: true
    }
  },
  {
    path: '/manage/payment',
    name: 'payment',
    component: PaymentManageComponent,
    meta: {
      auth: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.getters.logCheck) {
    next('/login')
    return
  }
  next()
})

export default router
