<template>
  <div
    style="
      width: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      height: 60px;
      position: absolute;
    "
  >
    <img
      @click="gohome()"
      src="../assets/images/Argong_Logo.png"
      style="
        width: 100px;
        margin-top: 12px;
        margin-left: 10px;
        float: left;
        cursor: pointer;
      "
      alt=""
    />
    <v-row justify="center">
      <div style="width: 80%; margin-top: 19px">
        <v-row justify="center">
          <div style="margin-top: 12px">
            <!-- <p
              @click="gonotice()"
              class="word"
              style="
                float: left;
                font-size: 20px;
                font-family: notosans_light;
                font-weight: bold;
                cursor: pointer;
                margin-right: 80px;
                margin-top: 9px;
              "
            >
              공지사항
            </p> -->
            <!-- <p
              class="word"
              style="
                float: left;
                font-size: 20px;
                font-family: notosans_light;
                font-weight: bold;
                cursor: pointer;
                margin-right: 80px;
                margin-top: 9px;
              "
            >
              가이드
            </p> -->
            <!-- <p
              @click="goboard()"
              class="word1"
              style="
                float: left;
                font-size: 20px;
                font-family: notosans_light;
                font-weight: bold;
                cursor: pointer;
                margin-right: 80px;
                margin-top: 9px;
                color: rgb(150, 150, 150);
              "
            >
              게시판
            </p> -->
            <p
              @click="gosupport()"
              class="word"
              style="
                float: left;
                font-size: 20px;
                font-family: notosans_light;
                font-weight: bold;
                cursor: pointer;
                margin-right: 80px;
                margin-top: 9px;
              "
            >
              고객 지원
            </p>

            <p
              @click="goservice()"
              class="word"
              style="
                float: left;
                font-size: 20px;
                font-family: notosans_light;
                font-weight: bold;
                cursor: pointer;
                margin-right: 80px;
                margin-top: 9px;
              "
            >
              서비스 관리
            </p>
          </div>
        </v-row>
      </div>
    </v-row>
    <v-btn
      v-if="$store.state.token == ''"
      @click="gologin()"
      width="100"
      height="40"
      style="
        background-color: #0c7ee5;
        color: white;
        font-family: notosans_light;
        font-weight: bold;
        font-size: 17px;
        position: absolute;
        right: 0;
        margin-right: 20px;
        top: 0;
        margin-top: 11px;
      "
      >로그인</v-btn
    >
    <!-- <div v-if="$store.state.token != ''">
      <p
        style="
          color: white;
          font-family: notosans_light;
          font-weight: bold;
          font-size: 20px;
          position: absolute;
          right: 0;
          margin-right: 150px;
          top: 0;
          margin-top: 15px;
        "
      >
        {{ $store.state.username }} 선생님
      </p>
    </div> -->
    <p
      @click="goguide()"
      class="word"
      style="
        font-family: notosans_light;
        font-weight: bold;
        position: absolute;
        right: 0;
        margin-right: 350px;
        color: white;
        margin-top: 18px;
        cursor: pointer;
        top: 0;
      "
    >
      사용자 가이드 보러가기
    </p>

    <p
      @click="golms()"
      class="word"
      style="
        font-family: notosans_light;
        font-weight: bold;
        position: absolute;
        right: 0;
        margin-right: 160px;
        color: white;
        margin-top: 18px;
        cursor: pointer;
        top: 0;
      "
    >
      LMS 페이지 이동하기
    </p>

    <v-btn
      v-if="$store.state.token"
      @click="logout()"
      width="100"
      height="40"
      style="
        background-color: #0c7ee5;
        color: white;
        font-family: notosans_light;
        font-weight: bold;
        font-size: 17px;
        position: absolute;
        right: 0;
        margin-right: 20px;
        top: 0;
        margin-top: 11px;
      "
      >로그아웃</v-btn
    >
  </div>
</template>

<script>
export default {
  methods: {
    gohome() {
      this.$router.push({ path: "/" }).catch(() => {});
    },
    gologin() {
      this.$router.push({ path: "/login" }).catch(() => {});
    },
    gonotice() {
      this.$router.push({ path: "/notice" }).catch(() => {});
    },
    logout() {
      sessionStorage.clear();
      this.$store.state.token = "";
      // this.$store.state.error.msg = "로그아웃 처리되었습니다.";
      // this.$store.state.error.errorStatus = true;
      this.$router.replace({ path: "/" }).catch(() => {});
    },
    goservice() {
      this.$router.push({ path: "/service" }).catch(() => {});
    },
    gosupport() {
      this.$router.push({ path: "/support" }).catch(() => {});
    },
    goboard() {
      return;
      //this.$router.push({ path: "/board" }).catch(() => {});
    },
    goguide() {
      window.open(
        "https://dnsoft.notion.site/b660596ed4b8496d9a56132d6b6cc670"
      );
    },
    golms() {
      window.open("https://manager.argong.io");
    },
  },
};
</script>

<style scoped>
.word {
  color: white;
}

.word:hover {
  border-bottom: 2px solid white;
  /* transition: 0.3s ease-out; */
}
</style>