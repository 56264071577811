import router from '../router/index'

const SET_LOGIN = async (state, data) => {
  if (data) {
    state.token = data
    sessionStorage.setItem('token', data)
    router.replace({ path: '/' }).catch(() => {})
  } else {
    state.error.msg = '로그인 정보를 확인해주세요'
    state.error.errorStatus = true
  }
}

const ERROR = async (state, msg) => {
  state.error.msg = msg
  state.error.errorStatus = true
}

export { SET_LOGIN, ERROR }
